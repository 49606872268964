import { createContext } from "react";


interface DashboardContextProps {
    filters: string[];
    setFilters: (filters: string[]) => void;
    fromDate: string;
    toDate: string;
    setDates: (fromDate: string, toDate:string) => void;
    project: any;
    projects: any[];
    setProject: (project:any) => void;
}

export const DashboardContext = createContext<DashboardContextProps>({
    filters: [], 
    setFilters: () => {},
    fromDate: "",
    toDate: "",
    setDates: () => {},
    project: {},
    projects: [],
    setProject: () => {}
});