import React, { useContext, useEffect} from "react"
import { useState } from "react";

import Fuse from "fuse.js";

import { DashboardContext } from "../context/DashboardContext";
import { clear, profile } from "console";

const check_icon = require("../img/check.png");
const search_icon = require("../img/search-icon.png");
const profile_icon = require("../img/user-icon.png");
const arrow_up = require("../img/arrow_up.png");

interface TimeFilterProps {
    sprints: any[];
}

function formatDate(dateString:string){
    // Create a new Date object
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    // Get the month name in the desired format
    const month = monthNames[date.getMonth()];

    // Get the day of the month
    const day = date.getDate();

    // Return the formatted date string
    return `${month} ${day}`;
    }

function formatSprintTime(sprint:any) {
    const startDate = new Date(sprint.start_date);
    const endDate = new Date(sprint.end_date);
    
    // Calculate the number of weeks between the dates
    const diffTime = endDate.getTime() - startDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffWeeks = Math.ceil(diffDays / 7);



    return (<p  className="text-lg">
               {`(${formatDate(sprint.start_date)} / ${formatDate(sprint.end_date)})`}
            </p>);
}

const TimeFilter:React.FC<TimeFilterProps> = ({sprints}:TimeFilterProps) =>{

    const {setDates, projects, project, setProject} = useContext(DashboardContext);

    const [selectedSprint, setSelectedSprint] = useState<any>(sprints[sprints.length-1]);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const [searchTem, setSearchTerm] = useState<string>("");
    const [filteredSprints, setFilteredSprints] = useState<any[]>(sprints);

    useEffect(() => {
        setFilteredSprints(sprints);
        if(sprints.length>0){
            setSelectedSprint(sprints[sprints.length-1]);
            setDates(sprints[sprints.length-1].start_date, sprints[sprints.length-1].end_date);
        }else{
            setSelectedSprint(null);
        }
    }, [sprints])
    
    const fuse = new Fuse(sprints, {
        keys: ['name'],
        threshold: 0.3
    })

    const handleSearch = (e:React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchTerm(query);
        if(query){
            const result = fuse.search(query).map(({item}) => item);
            setFilteredSprints(result);
        }else{
            setFilteredSprints(sprints);
        }
    }

    const ClearFilters = () => {
        setSearchTerm("");
        setFilteredSprints(sprints);
        setSelectedSprint(null); 
        setShowMenu(false); 
        setDates("", "");
    }
    

    return (
        <div>

            {showMenu && <div className="w-full h-[400px] bg-black fixed top-0 left-0 opacity-30 fade-in-30"></div>}

            <div className={"fixed bg-white w-full slide-menu "+(showMenu?"show":"hide") } style={{height:"80%", left:0, zIndex:9, overflow:"auto", paddingBottom:140}}>

                <div className="flex flex-row h-12 pt-4 mb-4">
                    {projects.map((item, index) => {
                        let c = "text-gray-500 text-xl px-8"
                        let b = "6px solid #fff"

                        if(project===item){
                            c = "text-purple text-xl px-8"
                            b = "6px solid #492CBB"
                        }

                        return(<button onClick={()=>{ setProject(item) }} className={c} style={{borderBottom:b, height:45}}>{item.name}</button>)
                    })}
                </div>

                <div className="pb-4 pt-4 mb-2 flex flex-row justify-stretch" style={{ borderBottom:"2px solid #EBEBEB" }}>
                    <div className="flex flex-row"
                        style={{
                                backgroundColor:"#FBFBFB",
                                borderColor:"#EDEAF3",
                                borderWidth:2,
                                padding:"14px 0px",
                                paddingLeft:14,
                                borderRadius:24,
                                width:"90%",
                                margin:"auto"
                        }}>
                        <img src={search_icon} style={{width:24, height:24, marginLeft:16}} alt="search icon"/>
                        <input 
                            className="rounded-xl"
                            style={{
                                backgroundColor:"#FBFBFB",
                                width:"100%",
                                margin:"auto"
                            }}
                            type="text w-full" 
                            placeholder="Search" 
                            value={searchTem}
                            onChange={handleSearch}
                            />
                    </div>
                </div>
                <div className="px-6">
                    {/* <button
                    onClick={ClearFilters}
                    className="w-full border-2 border rounded-xl border-purple
                                        text-purple font-bold text-xl py-4 mb-2">CLEAR FILTER</button> */}
                    {filteredSprints.map((sprint, index) => {
                        return(
                        <div 
                            className="flex flex-row justify-between py-4 mb-4 items-center cursor-pointer" 
                            key={"sprint-"+index} 
                            onClick={()=>{ 
                                if(selectedSprint===sprint){
                                    return;
                                }
                                setSelectedSprint(sprint); 
                                setShowMenu(false); 
                                setDates(sprint.start_date, sprint.end_date);
                            }}>
                            <div className="text-xl flex flex-row items-center">
                                <div style={{
                                    width:36,
                                    height:36,
                                    borderRadius:18,
                                    backgroundColor:"#EDEAF3",
                                    marginRight:8
                                }}>
                                    {selectedSprint && selectedSprint.name === sprint.name && <img src={check_icon} style={{width:36, height:36}} alt="check mark"/>}
                                </div>
                                <b>{sprint.name}</b>
                            </div>
                            {formatSprintTime(sprint)}
                        </div>)
                    })}

                    {filteredSprints.length===0 && <p className="text-center text-gray-500 mt-8 text-xl">No sprints found</p>}
                </div>
            </div>

            <div 
                onClick={() =>{ setShowMenu(!showMenu); setSearchTerm(""); setFilteredSprints(sprints);}}
                className="fixed w-full flex flex-row p-6 cursor-pointer left-0 bottom-0 bg-white items-center" 
                style={{
                    zIndex:10,
                    border: "2px solid #EEE9FF",
                    color:"#492CBB"
                    }}>

                        <div className="flex flex-col">
                            <img src={profile_icon} alt="" style={{width:36}} />
                            <p className="font-bold text-center">Me</p>
                        </div>

                        <div className="h-12 ml-4" style={{borderRight: "2px solid #EEE9FF"}}></div>

                        <div className="flex flex-row flex-nowrap items-center justify-center w-full">
                            <img src={arrow_up} alt="" style={{width:36, height:"auto", marginRight:8}} />

                            {selectedSprint &&
                            <p className="flex flex-wrap">
                                <b>{selectedSprint.name}</b>
                                {formatSprintTime(selectedSprint)}
                            </p>
                            }

                            {selectedSprint === null && 
                            <p>
                                No sprint selected
                            </p>
                            }

                        </div>

                {/* <p><b>{selectedSprint ? selectedSprint.name : sprints[sprints.length-1].name}</b></p>
                {formatSprintTime(selectedSprint? selectedSprint : sprints[sprints.length-1])} */}
            </div>

        </div>
    )
}
/* Input */



export default TimeFilter;