import React from "react";
import { useState, useEffect, createContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { sentiment_rgb, engagement_rgb, caring_rgb } from "../config";

import { DashboardContext } from "../context/DashboardContext";

import { TeamfloAPI } from "../manager/apimanager";

import Header from "../components/header";
import SprintHistory from "../components/sprintHistory";
import Ring from "../components/rint";
import Insight from "../components/insight";
import LineChart from "../components/lineChart";
import TimeFilter from "../components/timeFilter";
import BestMetrics from "../components/BestMetrics";
import Badges from "../components/Badges_V2";

import InsightManager from "../manager/insightManager";
import { debug } from "console";


const DashboardHomePage: React.FC = () =>{
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();


    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('User');


    const [projects, setProjects] = useState<any[]>([]);
    const [project, setProject] = useState<any>();
    
    const [insights, setInsights] = useState<any[]>([]);//holds all insigths
    const [filteredInsights, setFilteredInsights] = useState<any[]>([]);//holds the filtered insights for the text widgets.
    const [lineChartData, setLineChartData] = useState<any>([]);//holds the insights for the line chart.

    const [sprints, setSprints] = useState<any[]>([]);
    const [badges, setBadges] = useState<any[]>([]);
    const [filters, setFilters] = useState<string[]>([]);

    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");

    useEffect(()=>{
        if(searchParams.has("token")){
            const token = (searchParams.get("token") as string);
            GetData(token);
        }
        else{
            navigate("/");
        }
    }, []);

    useEffect(()=>{
        LoadProjectDetails();
    }, [project]);

    const LoadProjectDetails = async () => {
        if(project){
            const token = (searchParams.get("token") as string);
            const newInsights:any[] = [];
            const projectDetails = await TeamfloAPI.getProjectDetails(token, project.id);
            projectDetails.insights.forEach((insight:any) => { 
                newInsights.push(insight); 
            });
    
            setSprints(projectDetails.project.sprints);
    
            if(projectDetails.badges){
                setBadges(projectDetails.badges);
            }
    
    
            setInsights(newInsights);
    
            setFilteredInsights(newInsights);
            setLineChartData(newInsights);
            
            if(newInsights.length>0){
                setToDate(newInsights[newInsights.length-1].date_end);
                setFromDate(newInsights[newInsights.length-1].date_start);
            }else{
                setToDate("");
                setFromDate("");
            }
    
            setLoading(false);
        }
    }

    useEffect(()=>{ 
        console.log("Filters updated");
    },[filters]);

    useEffect(()=>{

        if(fromDate && toDate){
            const from = new Date(fromDate);
            const to = new Date(toDate);

            const from_linechard = new Date(from);
            const to_linechard = new Date(to);
            from_linechard.setDate(from_linechard.getDate() - 56);
            to_linechard.setDate(to_linechard.getDate() + 56);

            const filtered_:any[]= [];

            const filterdForChart:any[] = [];

            insights.forEach((insight:any) => {
                const start = new Date(insight.date_start);
                const end = new Date(insight.date_end);

                //hard filter is for the filtered insights for the text widgets.
                if(start >= from && end <= to){
                    filtered_.push(insight);
                }

                //for the linechart we want to show the previous and next four weeks around the filter date.
                if(start >= from_linechard && end <= to_linechard){
                    filterdForChart.push(insight);
                }
            });

            setFilteredInsights(filtered_);
            setLineChartData(filterdForChart);
            setFilters([]);
        }
        else{
            setFilteredInsights(insights);
            setLineChartData(insights);
            setFilters([]);
        }

    }, [fromDate, toDate]);

    const GetData = async (token:string) => {
        const data = await TeamfloAPI.getUser(token);

        if(data){
            setName(data.first_name);
            setProjects(data.projects);
            setProject(data.projects[0]);
        }
        else{
            navigate("/");
        }
    }

    const ToggleFilter = (type:string) => {
        //add or remove the filter from the filter array
        const newFilters = [...filters];
        if(filters.includes(type)){
          setFilters(newFilters.filter((f:string) => f !== type));
        }
        else{
          setFilters([...newFilters, type]);
        }
      }

    const filterActive = (type:string) => {
        console.log("Checking filter length: ", filters.length);
        if(filters.length===0){
            return false;
        }
        if(filters.length>0 && filters.includes(type)){
            return true;
        }
        return false;
    }

    if(loading){
        return <div>Loading...</div>
    }

    return (
        <DashboardContext.Provider value={{
            filters, 
            setFilters,
            fromDate,
            toDate,
            projects, 
            setProject,
            project,
            setDates: (fromDate: string, toDate:string) => {setFromDate(fromDate); setToDate(toDate);}
        }}>
        
        <div className="flex flex-col mx-auto items-center" style={{maxWidth:524, paddingBottom:264}}>
            <Header></Header>
            {filteredInsights.length>0 && <div>

            <SprintHistory sprints={sprints} />

            {/* <p className="text-center text-lg">Hello, {name}!</p> */}

            {/* <h2 className="text-center font-bold text-3xl">Here's your sprint recap</h2> */}

            <div className="bg-white rounded-xl mt-6 pl-10 pt-4 pr-4 pb-[37px]">
                <h4 className="font-bold text-dark ml-[-24px]">Your Performance</h4>
                <div className="flex flex-row mt-8">
                    <div className="RingContainer w-[132px] h-[132px]">
                        
                        <Ring 
                            color= {sentiment_rgb}
                            progress={InsightManager.GetLatest(filteredInsights, "sentiment_individual")?.data?.value}
                            strokeWidth={3}
                            size={40}
                            scale={1.4}
                            filter={"sentiment"}
                        ></Ring>
                        
                        <Ring 
                            color= {caring_rgb}
                            progress={InsightManager.GetLatest(filteredInsights, "caring_individual")?.data?.value}
                            strokeWidth={4.5}
                            size={40}
                            scale={1.05}
                            filter={"caring"}
                        ></Ring>

                        <Ring 
                            color= {engagement_rgb}
                            progress={InsightManager.GetLatest(filteredInsights, "engagement_individual")?.data?.value}
                            strokeWidth={7}
                            size={40}
                            scale={0.66}
                            filter={"engagement"}
                        ></Ring>
                        
                    </div>
                    <div className="flex-1 ml-8 flex flex-col justify-center">

                        <div 
                        onClick={()=>{ ToggleFilter("engagement") }}
                        style={{opacity: filters.length>0 && !filters.includes("engagement") ? 0.25 : 1}}
                        className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer">
                            <div className="w-[24px] mr-2">
                                <Ring 
                                    color= {engagement_rgb}
                                    progress={InsightManager.GetLatest(filteredInsights, "engagement_individual")?.data?.value}
                                    strokeWidth={9}
                                    size={40}
                                    scale={1}
                                ></Ring>
                            </div>
                            {/** this is needed for some reason?? */}
                            {filters.length>0 && <p style={{fontWeight: filterActive("engagement")  ? "bold" : "regular"}}>Engagement</p>}
                            {filters.length===0 && <p>Engagement</p>}
                        </div>

                        <div 
                        onClick={()=>{ ToggleFilter("caring") }}
                        style={{opacity: filters.length>0 && !filters.includes("caring") ? 0.25 : 1}}
                        className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer">
                            <div className="w-[24px] mr-2">
                                <Ring 
                                    color= {caring_rgb}
                                    progress={InsightManager.GetLatest(filteredInsights, "caring_individual")?.data?.value}
                                    strokeWidth={9}
                                    size={40}
                                    scale={1}
                                ></Ring>
                            </div>
                            {/** this is needed for some reason?? */}
                            {filters.length>0 && <p style={{fontWeight: filterActive("caring")  ? "bold" : "regular"}}>Caring</p>}
                            {filters.length===0 && <p>Caring</p>}
                        </div>

                        <div 
                        onClick={()=>{ ToggleFilter("sentiment") }}
                        style={{opacity: filters.length>0 && !filters.includes("sentiment") ? 0.25 : 1}}
                        className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer">
                            <div className="w-[24px] mr-2">
                                <Ring 
                                    color= {sentiment_rgb}
                                    progress={InsightManager.GetLatest(filteredInsights, "sentiment_individual")?.data?.value}
                                    strokeWidth={9}
                                    size={40}
                                    scale={1}
                                ></Ring>
                            </div>
                            {/** this is needed for some reason?? */}
                            {filters.length>0 && <p style={{fontWeight: filterActive("sentiment")  ? "bold" : "regular"}}>Sentiment</p>}
                            {filters.length===0 && <p>Sentiment</p>}
                        </div>
                    </div>
                </div>
            </div>

            <BestMetrics insights={lineChartData} />

            <LineChart insights={lineChartData} />
            

            {(filters.length === 0 || filters.includes("engagement")) && 
            <Insight 
            title={"Engagement"} 
            color={engagement_rgb}
            insight={InsightManager.GetLatest(filteredInsights, "engagement_individual")}/>
            }

            {(filters.length === 0 || filters.includes("caring")) && 
            <Insight 
                title={"Caring"} 
                color={caring_rgb}
                insight={InsightManager.GetLatest(filteredInsights, "caring_individual")}/>
            }

            {(filters.length === 0 || filters.includes("sentiment")) && 
            <Insight 
                title={"Sentiment"}
                color={sentiment_rgb}
                insight={InsightManager.GetLatest(filteredInsights, "sentiment_individual")}/>
            }
            

            <Badges badges={badges}></Badges>
        
            </div>}


            {
                filteredInsights.length===0 && <p className="text-center text-gray-500 mt-8 text-xl">No insights found</p>
            }

            <TimeFilter sprints={sprints}/>
        </div>
        </DashboardContext.Provider>
    );
}

export default DashboardHomePage; // Path: src/routes/dashboard.tsx