import Ring from "./rint";

import { engagement_rgb } from "../config";

interface SprintHistoryProps {
    sprints: any[];
}

const SprintHistory = ({sprints}:SprintHistoryProps) =>{

    //split the sprints with the last 6 elements
    sprints = sprints.slice(-6);
    
    return (
        <div className="flex flex-row justify-between my-4 " >
            {sprints.map((sprint:any, index: number)=>{
                const v = Math.floor(Math.random() * 100)
                return <div className="w-[36px] relative cursor-pointer" style={{opacity:index===sprints.length-1?1:0.25}}> 
                <Ring 
                color= {engagement_rgb}
                progress={v}
                strokeWidth={6}
                size={40}
                scale={1.4}
                ></Ring>

                <p className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">{v}</p>
                
                </div>

            })}
        </div>
    )
}

export default SprintHistory;