import { useContext, useState, useEffect } from "react";
import { DashboardContext } from "../context/DashboardContext";

import badge_icons from "../constants/badges_img";
import { createPortal } from "react-dom";

interface BadgesProps {
    badges: any[];
}


const Badges: React.FC<BadgesProps> = ({badges}) => {

    const {fromDate, toDate} = useContext(DashboardContext);
    const [filteredBadges, setFilteredBadges] = useState<any[]>(badges);

    const [detailBadge, setDetailBadge] = useState<any>(null);

    function GetBadgeImage(props:any):any{
        console.log("Getting image for Badge: ", props.badge);
        var s = props.badge.streak-1;
        var style="w-[224px] mx-auto relative";
        if(s>2){
            style = "w-[324px] mx-auto relative"
        }
        if(s>4){
            s = 4;
        }
        //default value
        var src = badge_icons["engagement_individual"][0]
        if(props.badge.badge_type === 'all_rounder'){
            if(props.badge.badge_level==="gold"){
                src = badge_icons[props.badge.badge_type][1]
            }
            else{
                src = badge_icons[props.badge.badge_type][0]
            }
        }
        else{
            src = badge_icons[props.badge.badge_type][s];
        }
        
       
        return(<img className={style} src={src} alt="" />)
    }

    useEffect(() => {
        //filter badges based on the dates via the awarded_date property
        if(fromDate !== "" && toDate !== ""){
            const filteredBadges_:any[] = badges.filter((badge)=>{
                const awarded_date = new Date(badge.awarded_date);
                return awarded_date >= new Date(fromDate) && awarded_date <= new Date(toDate);});

            setFilteredBadges(filteredBadges_);
        }else{
            setFilteredBadges(badges);
        }
    }, [fromDate, toDate]);

    if(filteredBadges.length === 0){
        return null;
    }

    return (
    <div className="w-full rounded-xl bg-white mt-6 p-8" style={{maxWidth:424, minHeight:80}}>
        <h4 className="text-left font-bold text-dark">Achievements</h4>
        <div className="flex flex-row flex-wrap">
        {filteredBadges.map((badge, index) => {
                return(
                    <div className="w-[30%] mr-[3%] mt-4 cursor-pointer" onClick={()=>{ setDetailBadge(badge); }}>
                    <GetBadgeImage badge={badge} />
                    <p className="text-center text-dark">{badge.name}</p>
                </div>
                )
            })
        }
        </div>

        {detailBadge && createPortal(
        <div className="popup-container">
            <div className="popup-content">
                <span className="close cursor-pointer" onClick={()=>{setDetailBadge(null)}}>&times;</span>
                <GetBadgeImage badge={detailBadge} />
                <h2 className="font-bold text-dark text-3xl">{detailBadge.name}</h2>
                <p>{detailBadge.description}</p>
            </div>
        </div>, document.body)}

    </div>)
}

export default Badges;